import css from './sass/style.scss';

//require('./js/vidbg')
require('./js/fonts')
require('./js/helpers')
require('./js/init')
require('./js/career')
// require('./js/cookies2')
require('./js/cookieconsent')
require('./js/countup')
require('./js/filters')
require('./js/gototarget')
require('./js/homevideo')
require('./js/inview')
require('./js/intl')
require('./js/ismobile')
//require('./js/map')
require('./js/nav')
require('./js/polyfills')
require('./js/splitting')
require('./js/carousels')
require('./js/glightbox')
require('./js/competences')
//require('./js/cookies')
require('./js/text-roller')
require('./js/partners_cooperation')
require('./js/validate')
require('./js/contactmap')
require('./js/zenbed')
