import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

if(!window.cookieconsent) {
    window.cookieconsent = {


        cookie: {
            name: 'cc_cookie',
        },

        guiOptions: {
            consentModal: {
                layout: 'cloud inline',
                position: 'bottom center',
                equalWeightButtons: true,
                flipButtons: false
            },
            preferencesModal: {
                layout: 'box',
                equalWeightButtons: true,
                flipButtons: false
            }
        },

        categories: {
            necessary: {
                enabled: true,
                readOnly: true
            },
            analytics: {
            },
            ads: {}
        },

        language: {
            default: 'pl',
            translations: {
                pl: {
                    consentModal: {
                        title: 'Używamy plików cookie',
                        description: '<p>Strona wykorzystuje pliki cookies w celu prawidłowego jej działania, korzystania z narzędzi analitycznych (Google Analytics), marketingowych (Facebook, Instagram, LinkedIn) oraz odtwarzacza wideo (YouTube, Vimeo). Jeżeli wyrażasz zgodę na wykorzystywanie plików cookies, kliknij w przycisk "Akceptuję wszystkie".</p>',
                        acceptAllBtn: 'Akceptuję wszystkie',
                        acceptNecessaryBtn: 'Odrzuć wszystkie',
                        showPreferencesBtn: 'Zarządzaj indywidualnymi preferencjami',
                        footer: `

                    `,
                    },
                    preferencesModal: {
                        title: 'Zarządzaj indywidualnymi preferencjami',
                        acceptAllBtn: 'Akceptuję wszystkie',
                        acceptNecessaryBtn: 'Odrzuć wszystkie',
                        savePreferencesBtn: 'Akceptuje aktualne ustawienia',
                        closeIconLabel: 'Zamknij domyślne ustawienia',
                        serviceCounterLabel: 'Service|Services',
                        sections: [
                            {
                                title: 'Tylko niezbędne ciasteczka',
                                description: '<p>Niezbędne pliki cookie są absolutnie niezbędne do prawidłowego funkcjonowania witryny. Te pliki cookie zapewniają anonimowo podstawowe funkcje i zabezpieczenia witryny.</p>',

                                linkedCategory: 'necessary'
                            },
                            {
                                title: 'Analityczne ciasteczka',
                                description: '<p>Analityczne pliki cookie służą do zrozumienia, w jaki sposób odwiedzający wchodzą w interakcję z witryną. Te pliki cookie pomagają dostarczać informacji na temat liczby odwiedzających, współczynnika odrzuceń, źródła ruchu itp.</p>',
                                linkedCategory: 'analytics',
                            }
                        ]
                    }
                }
            }
        }
    };
}

document.addEventListener("DOMContentLoaded", function () {

    const consentApp = () => {
        const GTM_ID = 'GTM-TXH2MCW';

        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}

        gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied',
            'functionality_storage': 'denied',
            'personalization_storage': 'denied',
            'security_storage': 'granted',
        });

        gtag('js', new Date());
        gtag('config', GTM_ID);

        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id='+GTM_ID;
        document.head.appendChild(script);

        const updateGtagConsent = () => {
            gtag('consent', 'update', {
                'ad_storage': CookieConsent.acceptedCategory('marketing') ? 'granted' : 'denied',
                'ad_user_data': CookieConsent.acceptedCategory('marketing') ? 'granted' : 'denied',
                'ad_personalization': CookieConsent.acceptedCategory('marketing') ? 'granted' : 'denied',
                'analytics_storage': CookieConsent.acceptedCategory('analytics') ? 'granted' : 'denied',
                'functionality_storage': CookieConsent.acceptedCategory('marketing') ? 'granted' : 'denied',
                'personalization_storage': CookieConsent.acceptedCategory('marketing') ? 'granted' : 'denied',
                'security_storage': 'granted', //necessary
            });
        };
        window.updateGtagConsent = updateGtagConsent;

        window.addEventListener('cc:onConsent', () => {
            updateGtagConsent();
        });

        window.addEventListener('cc:onChange', () => {
            updateGtagConsent();
        });
    }

    if(window.cookieconsent) {
        CookieConsent.run(window.cookieconsent);
        consentApp();
    }
});
