import InView from 'inview';

document.addEventListener('DOMContentLoaded', ()=> {

    const el = document.querySelectorAll('.js-zenbed-btn');
    if(!el) return;
    
    const modal = document.getElementById('calendarModal');

    for (let i = 0; i < el.length; i++) {
        el[i].addEventListener('click', (e)=> {
            e.preventDefault();
    
/*
            if (document.body.classList.contains('page-contact')) {
                
                let offset = document.querySelector('.js-top').clientHeight,
                    window_pos = window.pageYOffset || window.scrollY || document.documentElement.scrollTop,
                    target = window_pos + document.querySelector('.c-contact').getBoundingClientRect().top - offset;
                cutme.Helpers.scrollTo(target, .25, 0);
    
            } else {
*/
                document.body.classList.toggle('is-zenbed-show');
                modal.src = 'https://calendar.google.com/calendar/appointments/schedules/AcZssZ3m_GLmb1iL2Y3fZXMZDvUPlBufcLTA9ayUwTqPrFmwGSyjJYIi882q-ncKRCnD2O4X8D_NnIX3?gv=true';
                // Pokaż modal
                modal.style.display = 'flex';
//            }
        });
    }
    
        
    const inview = InView(document.querySelector('.c-footer'), function(isInView) {
        isInView ? document.body.classList.add('hide-zenbed-btn') : document.body.classList.remove('hide-zenbed-btn');
    })

    let timer = setInterval(()=>{

        //let zenbedEl = document.querySelector('.c-zenbed .jss12');
        let zenbedEl = document.querySelector('.c-zenbed');        
        
        if(zenbedEl) {
            const btn = document.createElement('button');
            btn.classList.add('c-zenbed__btn');
            btn.type = 'button';
            btn.innerHTML = '<span class="icon-close"></span>';
            btn.addEventListener('click', (e)=> {
                e.preventDefault();
                document.body.classList.remove('is-zenbed-show');
                modal.src = '';
                modal.style.display = 'none';
                
            });
            zenbedEl.append(btn)
            
            document.addEventListener('keydown', function(evt) {
                // evt = evt || window.event;
                var isEscape = false;
                if ("key" in evt) {
                    isEscape = (evt.key == "Escape" || evt.key == "Esc");
                } else {
                    isEscape = (evt.keyCode == 27);
                }
                if (isEscape) {
                    document.body.classList.remove('is-zenbed-show');
                }
        });

            clearInterval(timer);
        }

    },400);

});
