const { detect } = require('detect-browser');
const browser = detect();
import Blazy from 'blazy';

document.addEventListener('DOMContentLoaded',function() {

    if (browser) {
        document.documentElement.classList.add(browser.name);
    }
    
    document.body.classList.add('is-loaded');
        
    setTimeout(function() {
        cover.remove();
    }, 250);

    const cover = document.getElementById('cover');
    
    const init = function() {
        document.body.removeAttribute('style');
        
        // Anims on inview
        window.animsInit()   
        
        // Carousels 
        document.querySelectorAll('.js-caseteam').length > 0 ? window.caseTeamCarousel() : false;
        document.getElementById('clients') ? window.clientsCarousel() : false;
        document.getElementById('partners') ? window.partnersCarousel() : false;
        document.querySelectorAll('.js-projects').length > 0 ? window.projectsCarousel() : false;
        document.getElementById('team') ? window.teamCarousel() : false;
        document.querySelectorAll('.js-case').length > 0 ? window.casesCarousel() : false;
 
        // Blazy
        
        window.bLazy = new Blazy({
            success: function(el){
            }
        });


        // Home video
        
        document.getElementById('video') ? window.homeVideo() : false;      
        
        
        // Grid
        //document.getElementById('grid') ? window.grid() : false;
        
    };
    
    
    window.addEventListener('load', init);

}, false);